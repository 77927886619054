* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style: none;
}

.chat {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
.footer-contact span img {
  margin-right: 7px;
}
a {
  text-decoration: none;
  color: black;
}
.footer a {
  color: #fff !important;
}
.formBtn {
  background-color: #b9001f;
  color: aliceblue;
  width: 100%;
  border: none;
  cursor: pointer;
  padding: 12px;
  margin-top: 1rem;
  font-size: 1rem;
}
.formBtn:hover {
  background-color: brown;
}
.formBtn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
/* scroll trigger */
.Pricing button {
  position: absolute;
  bottom: 1rem;
  right: 50%;
  transform: translateX(50%);
  padding: 0.4rem 2rem;
  background-color: #c8102e;
  color: #fff;
  border: 2px solid #c8102e;
  border-radius: 7px;
  cursor: pointer;
  font-size: 0.9rem;
}
