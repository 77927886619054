.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none !important;
  background-color: #f9f9f9;
  width: 1000px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 100;
  position: absolute;
  left: 50%;

  transform: translateX(-50%);
}
.drop-anchor{
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
}
.drop-anchor:hover{
  color: red;

}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block !important;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
}
.dropdown-content:hover {
  display: block !important;
}
.dropdown > a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}
@media only screen and (max-device-width: 1100px) {
  .dropdown:hover .dropdown-content {
    display: block !important;
  }
  .dropdown-content {
    width: 100vw !important;
    height: 60vh;
    position: fixed;
    overflow: auto;
    top: 0 !important;
    overflow: scroll !important;
  }
  
  .dropdown-content a {
    color: black;
    text-decoration: none;
    display: block;
  }
} 